
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");


/*------------------------ header -----------------------------------------------*/



/*------------------------------- Contact us form section --------------------------------------------------*/
.container {
  /* position: relative; */
  /* width: 100%; */
  min-height: 40vh;
  padding: 2rem 0rem;
  background-color:rgb(28, 27, 27);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form {
  width: 100%;
  max-width: 1089px;
  background-color: #0c0c0c;
  border-radius: 24px;
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.462);
  z-index: 1;
  overflow: hidden;
  display: grid;
  /* margin-top: -80%; */
  grid-template-columns: repeat(2, 1fr);
}


/* ###############icon grid################################## */
.contact-info {
  padding: 2.3rem 2.2rem;
  position: relative;
  background-color:#040404;
  font-family: Poppins;
  color: #F9F9F9;
}

.contact-info .title {
  color: #78A92D;
}

.text {
  color: #333;
  margin: 1.5rem 0 2rem 0;
}



/*  */






.contact-form {
  background: linear-gradient(186deg, rgb(160, 166, 153) 9%, rgb(160, 162, 157) 14%, rgb(151, 155, 136) 40%, rgb(141, 143, 128) 65%);;
  position: relative;
}
.contact-form:before {
  content: "";
  position: absolute;
  width: 26px;
  height: 26px;
  /* background-color: #78A92D; */
  transform: rotate(45deg);
  top: 50px;
  left: -13px;
}
.contact-form-background{
  background-color: #fafafa;
  width: 100%;
  height: 75%;
  /* left: 669px; */
  /* padding:0rem 1rem; */
  margin-left:-80px;
  /* top: 1597px; */
  border-radius: 30px;
  /* display: flex; */
  /* margin-left: auto;
  margin-right: auto; */
}
form {
  padding: 2.3rem 2.2rem;
  z-index: 10;
  overflow: hidden;
  position: relative;
  margin-top: 120px;
}
.information {
  display: flex;
  color: #555;
  margin: 0.7rem 0;
  align-items: center;
  font-size: 0.95rem;
}

.title1 {
  color: #fafafa;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
  margin-bottom: 0.7rem;
}
.image-grid{
  margin-top: 60px;
}
.input-container {
  position: relative;
  /* margin: 1rem 0rem; */
  margin-left: auto;
  margin-right: auto;
  margin: 1rem 0rem;
  display: flex;
}

.input {
  width: 100%;
  outline: none;
  border: 2px solid #fafafa;
  background: rgba(196, 196, 196, 0.5);
  padding: 0.6rem 1.2rem;
  color: black;
  font-weight: 500;
  font-size: 0.95rem;
  letter-spacing: 0.5px;
  border-radius: 25px;
  transition: 0.3s;
}

textarea.input {
  padding: 0.8rem 1.2rem;
  min-height: 150px;
  border-radius: 22px;
  resize: none;
  overflow-y: auto;
}

.input-container label {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  padding: 0 0.4rem;
  color: black;
  font-size: 0.9rem;
  font-weight: 400;
  pointer-events: none;
  z-index: 1000;
  transition: 0.5s;
}

.input-container.textarea label {
  top: 1rem;
  transform: translateY(0);
}
.buttons{
  text-align:center;
}

.btn {
  padding: 0.6rem 1.3rem;
  background-color: #080e00;
  border: 2px solid #fafafa;
  font-size: 0.95rem;
  color: #fcfffa;
  line-height: 1;
  border-radius: 25px;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  margin-right: 2rem;
  margin: 0;
}

.btn:hover {
  background-color: #9cdb3d;
  color: #fafafa;
}

.input-container span {
  position: absolute;
  top: 0;
  left: 25px;
  transform: translateY(-50%);
  font-size: 0.8rem;
  padding: 0 0.4rem;
  color: transparent;
  pointer-events: none;
  z-index: 500;
}

.input-container span:before,
.input-container span:after {
  content: "";
  position: absolute;
  width: 10%;
  opacity: 0;
  transition: 0.3s;
  height: 5px;
  background-color:#fafafa;
  top: 50%;
  transform: translateY(-50%);
}

.input-container span:before {
  left: 50%;
}

.input-container span:after {
  right: 50%;
}

.input-container.focus label {
  top: 0;
  transform: translateY(-50%);
  left: 25px;
  font-size: 0.8rem;
}

.input-container.focus span:before,
.input-container.focus span:after {
  width: 50%;
  opacity: 1;
}



/* -------------------------------------------------footer---------------------------------------------------------------------- */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
  

  
  @media (max-width: 700px){
    footer{
      position: relative;
    }
    .content .top .logo-details{
      font-size: 26px;
    }
    .content .top .media-icons a{
      height: 35px;
      width: 35px;
      font-size: 14px;
      line-height: 35px;
    }
    footer .content .link-boxes .box{
      width: calc(100% / 3 - 10px);
    }
    footer .content .link-boxes .input-box{
      width: 60%;
    }
    .bottom-details .bottom_text span,
    .bottom-details .bottom_text a{
      font-size: 12px;
    }
  }
  @media (max-width: 520px){
    footer::before{
      top: 145px;
    }
    footer .content .top{
      flex-direction: column;
    }
    .content .top .media-icons{
      margin-top: 16px;
    }
    footer .content .link-boxes .box{
      width: calc(100% / 2 - 10px);
    }
    footer .content .link-boxes .input-box{
      width: 100%;
    }
    .new_footer_logo{
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
    .new_devesh_logo{
        display: none;
    }
    .policy_terms{
      display: flex;
      margin-right: -2.5rem !important;
      
    }
    .copyright_text{
      margin-left: -2rem !important;
    }
    
  }
  

.new_footer_logo{
    width: 40%;
}
.new_devesh_logo{
    width: 50vw;
    position: absolute;
    margin-left: 32%;
    margin-top: 7%;
}
.copyright_text{
    margin-left: 40%;
}

/* ---------------------------------------------------media queries------------------------------------------------------------------------ */
@media (max-width: 850px) {
  .form {
    grid-template-columns: 1fr;
    /* margin-top: -40%;   */
  }
  .container1{
    height: 20vh;
  }

  .contact-info:before {
    bottom: initial;
    top: -75px;
    right: 65px;
    transform: scale(0.95);
  }
  .contact-form-background{
    background-color: #fafafa;
    width: 100%;
    height: 75%;
    left: 669px;
    /* margin-left:-50px; */
    margin: 1px;
    top: 1597px;
    border-radius: 30px;
  }
  .heading2{
    font-family: 'Pacifico',cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 64px;
    line-height: 158%;
    /* or 101px */
    text-align: center;
    letter-spacing: 0.08em;
    color: #78A92D;
    justify-content: center;
    text-align: center;
  }
  .heading3{

  margin-top: 100px;
  font-size: 5.5vw;
    
  }
  .sub-heading3{
    /* margin-top: 100px; */
  font-size: 5.5vw;

  }

  .contact-form:before {
    top: -13px;
    left: initial;
    right: 70px;
  }

  .text {
    margin: 1rem 0 1.5rem 0;
  }

  .social-media {
    padding: 1.5rem 0 0 0;
  }
}


@media (max-width: 480px) {
  .container {
    z-index: 1;
    padding: 1.5rem;
    /* padding-bottom: .01rem !important; */
    min-height: 98vh;

  }

  .container1{
    /* min-width: 15vh; */
    min-height: 15vh !important;
  }

  .contact-info:before {
    display: none;
  }
 

  .square,
  .big-circle {
    display: none;
  }
  .contact-form-background{
    background-color: #fafafa;
    width: 100%;
    height: 95%;
    left: 669px;
    /* margin-left:-50px; */
    margin-top:-30%;
    /* margin:1px;    */
    top: 1597px;
    border-radius: 30px;
  }
  .container2 .box{
    margin-left: 5%;
  }

  form,
  .contact-info {
    padding: 1.7rem 1.6rem;
  }

  .text,
  .information,
  .social-media p {
    font-size: 0.8rem;
  }

  .title {
    font-size: 1.15rem;
  }
  .image-grid{
    display: none ;
  }

  .customer-support {
    width: 3px;
    height: 30px;
    line-height: 30px;
  }
  .heading2{
    font-family: 'Pacifico',cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 64px;
    line-height: 158%;
    margin: 50px;
    text-align: center;
    letter-spacing: 0.08em;
    color: #78A92D;
    justify-content: center;
    text-align: center;
  
  
  }
  .heading3{

    margin-top: 2000px;
    font-size: 10vw;


      
    }
    .sub-heading3{
      /* margin-top: 100px; */
      font-size: 10vw;

    }

  .icon {
    width: 23px;
  }

  .input {
    padding: 0.45rem 1.2rem;
  }

  .btn {
    padding: 0.45rem 1.2rem;
  }
}

